"use client";

// External imports
import React from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper/modules';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

// Internal imports
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { Grid, Column } from "@/components/layout/Grid";
import { LayoutIntro } from "@/components/LayoutIntro";

// Type Imports
import type { IDynamicPosts } from "@/types/ACFLayouts/DynamicPosts";
import { ViewMoreLink } from "@/components/buttons/ViewMoreLink";
import { ACFCardBuilder } from "@/components/acfFlexibleContent/ACFCardBuilder";
import { useGetUser } from "@/hooks/useGetUser";
import Link from "next/link";
import { HCard, HCardsContainer, VCard } from "@/components/cards/CardElements";
import styles from '@/components/keenSlider/KeenSlider.module.css'

type Props = {
  data: IDynamicPosts;
  nested?: boolean;
};

export const DynamicPosts = ({ data, nested = false }: Props) => {
  const { data: userData, isLoading } = useGetUser();
  const [opacities, setOpacities] = React.useState<number[]>([])

  // Default ACF Settings
  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  const paddedClasses =
    data.orientation === "horizontal" &&
    (data.card_variant === "padded" || data.card_variant === "series");

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "dynamic-posts-block",
    "full-wrapper",
    paddings,
    margins,
    classes,
    paddedClasses && "padded-cards",
    data.display_in_a_slider ? "slider" : "columns"
  );

  let perView = +data.number_of_columns;

  if (data.orientation === "horizontal") {
    // Override the panelsPerView property
    perView = data.horizontal_columns === "stack" ? 1 : 2;
  }

  if (hide) {
    return <></>;
  }

  return (
    <>
      <div
        id={id}
        className={`${combinedClasses}`}
        style={{ ...bgImgStyles, backgroundColor }}
      >
        <div className={`${!nested ? data.wrapper_width : ""}`}>
          {/*  Grid Cards - No Slider
           - Check first to see if we have any posts and fallback if not...
           */}
          {!data.display_in_a_slider && (
            <>
              {data.posts.length > 0 ? (
                <>
                  {/* Intro */}
                  {data.intro && (
                    <LayoutIntro intro={data.intro}>
                      {data.view_more_link &&
                        typeof data.view_more_link === "object" && (
                          <ViewMoreLink view_more_link={data.view_more_link} />
                        )}
                    </LayoutIntro>
                  )}

                  {/* Horizontal Cards
                   - These don't use the Grid component
                   hence being seperated out from the Vertical Cards.
                   */}
                  {data.orientation === "horizontal" && (
                    <>
                      <HCardsContainer
                        stacking={data.stacking}
                        direction={data.zz_direction}
                        columns={data.horizontal_columns}
                      >
                        {data.posts.map((post, index) => (
                          <HCard
                            key={`dynamic-post-${post.ID}`}
                            post={post}
                            classes={`${data.card_classes} 
                            ${paddedClasses ? "padding border border-solid border-pn-black-base" : ""}`}
                          >
                            <ACFCardBuilder data={data} post={post} />
                          </HCard>
                        ))}
                      </HCardsContainer>
                    </>
                  )}

                  {/* Vertical Cards  */}
                  {data.orientation === "vertical" && (
                    <Grid classes="same-height-group">
                      {/* Columns */}
                      {data.posts.map((post, index) => (
                        <Column
                          key={`vertical-dynamic-post-${post.ID}`}
                          columns={+data.number_of_columns}
                        >
                          <VCard classes={data.card_classes} post={post}>
                            <ACFCardBuilder data={data} post={post} />
                          </VCard>
                        </Column>
                      ))}
                    </Grid>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {/* Slider of Posts
           - Check first to see if we have any posts and fallback if not...
           */}
          {data.display_in_a_slider && (
            <>
              {data.posts || data.series ? (
                <>
                  {data.orientation === "horizontal" ? (
                    <HCardsContainer
                      columns={data.horizontal_columns}
                      direction={data.zz_direction}
                      stacking={data.stacking}
                      classes={"same-height-group"}
                    >
                      {data.view_more_link &&
                        typeof data.view_more_link === "object" && (
                          <ViewMoreLink
                            classes="max-[799px]:hidden"
                            view_more_link={data.view_more_link}
                          />
                        )}

                      <Swiper
                        slidesPerView={perView}
                        modules={[EffectFade, Navigation]}
                        effect={'fade'}
                        navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev', enabled: true }}
                        onInit={(swiper) => setTimeout(() => swiper.update(), 300)}
                        autoHeight={true}
                      >
                        <div className="swiper-arrows flex z-[5] gap-2.5 !mt-5">
                          <div className="swiper-arrow swiper-prev"></div>
                          <div className="swiper-arrow swiper-next"></div>
                        </div>
                        <>
                        {/* Main Section */}
                          {data.type_of_data === 'posts' &&
                            data.posts &&
                            data.posts.map((post, index) => (
                              <SwiperSlide className={"bg-white"} key={`slider-${post.ID}`}>
                                <HCard
                                  post={post}
                                  classes={`${data.card_classes} 
                                ${paddedClasses ? 'padding border border-solid border-pn-black-base' : ''}`}
                                >
                                  <ACFCardBuilder data={data} post={post}/>
                                </HCard>
                              </SwiperSlide>
                            ))}

                          {/*  Series  */}
                          {data.type_of_data === 'series' &&
                            data.series &&
                            data.series.map((post, index) => {
                              post.slug = post.permalink

                              return (
                                <SwiperSlide key={`slide-${post.post_title}`}>
                                  <HCard
                                    post={post}
                                    classes={`!mb-0 ${data.card_classes} 
                                  ${paddedClasses ? 'padding border border-solid border-pn-black-base' : ''}`}
                                  >
                                    <ACFCardBuilder data={data} post={post}/>
                                  </HCard>

                                  {!userData?.user && (
                                    <p
                                      className="font-roboto-serif underline font-semibold
                                    absolute right-10 bottom-10 max-[1200px]:hidden"
                                    >
                                      <Link href={'/register'}>
                                        Sign up to begin this course
                                      </Link>
                                    </p>
                                  )}
                                </SwiperSlide>
                              )
                            })}
                        </>
                      </Swiper>
                    </HCardsContainer>
                  ) : (
                    <>
                      {/* Intro */}
                      {data.intro && <LayoutIntro intro={data.intro} />}

                      <Swiper
                        slidesPerView={perView}
                        spaceBetween={20}
                        modules={[Navigation]}
                        navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev', enabled: true }}
                        className="same-height-group"
                        onInit={(swiper) => setTimeout(() => swiper.update(), 300)}
                        autoHeight={true}
                      >
                        <div className="swiper-arrows flex z-[5] gap-2.5 !mt-5">
                          <div className="swiper-arrow swiper-prev"></div>
                          <div className="swiper-arrow swiper-next"></div>
                        </div>
                        {data.type_of_data === 'posts' &&
                          data.posts &&
                          data.posts.map((post, index) => (
                            <SwiperSlide className={"bg-white"} key={`slide-${post.ID || post.id}`}>
                              <VCard classes={data.card_classes} post={post}>
                                <ACFCardBuilder data={data} post={post}/>
                              </VCard>
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
